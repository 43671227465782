
import { defineComponent, ref, Ref, PropType, watch } from "vue";
import { Tag } from "../../types";

export const TagsComponent = defineComponent({
  props: {
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    activeClass: {
      type: Object,
      default: () => {
        return { type: "secondary", ghost: false };
      },
    },
    inactiveClass: {
      type: Object,
      default: () => {
        return { type: "default", ghost: false };
      },
    },
    mode: {
      type: String,
      default: () => "multi",
    },
    data: {
      type: Object as PropType<Array<Tag> | { [key: string]: Omit<Tag, "id"> }>,
      required: true,
    },
    emptyText: {
      type: String,
      default: () => "No data",
    },
    loading: {
      type: Number,
      default: () => 0,
    },
    value: {
      type: Object as PropType<Set<string>>,
      required: true,
    },
  },
  emits: ["update:value", "change"],
  setup(props, { emit }) {
    const model: Ref<Set<string>> = ref(props.value);

    const setItem = (itemId: Tag["id"]) => {
      let update = false;
      if (props.value.has(itemId)) {
        if (props.allowEmpty || (!props.allowEmpty && props.value.size !== 1)) {
          model.value.delete(itemId);
          update = true;
        }
      } else {
        if (props.mode === "single" && model.value.size > 0) {
          model.value.clear();
        }
        model.value.add(itemId);
        update = true;
      }

      if (update) {
        emit("update:value", model.value);
        emit("change", model.value);
      }
    };

    watch(
      () => props.value,
      (nv) => {
        model.value = nv;
      }
    );

    return {
      setItem,
      model,
    };
  },
});

export default TagsComponent;
