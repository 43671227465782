import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a96cc5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tags" }
const _hoisted_2 = {
  key: 0,
  class: "tags__wrapper"
}
const _hoisted_3 = { class: "anticon" }
const _hoisted_4 = { class: "tags__tag-desc" }
const _hoisted_5 = {
  key: 1,
  class: "tags__wrapper tags__wrapper--loading"
}
const _hoisted_6 = {
  key: 2,
  class: "tags__wrapper tags__wrapper--empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, key) => {
            return (_openBlock(), _createBlock(_component_a_button, {
              class: _normalizeClass([
          'tags__tag',
          `tags__tag--${Array.isArray(_ctx.data) ? item.id : key}`,
          _ctx.value.has(Array.isArray(_ctx.data) ? item.id : key)
            ? 'tags_tag--active'
            : '',
        ]),
              key: Array.isArray(_ctx.data) ? item.id : key,
              disabled: item.disabled,
              size: 'large',
              ghost: 
          _ctx.value.has(Array.isArray(_ctx.data) ? item.id : key)
            ? _ctx.activeClass.ghost
            : _ctx.inactiveClass.ghost
        ,
              type: 
          _ctx.value.has(Array.isArray(_ctx.data) ? item.id : key)
            ? _ctx.activeClass.type
            : _ctx.inactiveClass.type
        ,
              onClick: ($event: any) => (_ctx.setItem(Array.isArray(_ctx.data) ? item.id : key))
            }, _createSlots({
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(item.name) + " ", 1 /* TEXT */),
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.desc), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, [
              (item.icon)
                ? {
                    name: "icon",
                    fn: _withCtx(() => [
                      _createElementVNode("span", _hoisted_3, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                      ])
                    ])
                  }
                : undefined
            ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "disabled", "ghost", "type", "onClick"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : (_ctx.loading > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(new Array(_ctx.loading).fill(0), (emptySlot, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "tags__tag tags__tag--empty",
                key: index
              }, [
                _createVNode(_component_a_button, {
                  loading: true,
                  type: _ctx.inactiveClass.type,
                  ghost: _ctx.inactiveClass.ghost,
                  disabled: true
                }, null, 8 /* PROPS */, ["type", "ghost"])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_a_empty, { description: _ctx.emptyText }, null, 8 /* PROPS */, ["description"])
          ]))
  ]))
}